import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["type", "id", "readonly"]
const _hoisted_2 = ["for"]

import { computed, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputComponent',
  props: {
    inputType: { default: 'text' },
    placeHolder: {},
    errorMessage: {},
    modelValue: {},
    readOnly: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const emit = __emit;
const inputComponent = ref<HTMLInputElement | null>(null);

const props = __props;

const id = Math.random().toString(36).slice(2);

const val = computed({
  get: () => props.modelValue,
  set: (val) => {
    emit('update:modelValue', val);
  },
});

function clearModelValue() {
  val.value = '';
}

function focusInput() {
  inputComponent.value?.focus();
}

function isInFocus() {
  return document.activeElement === inputComponent.value;
}

__expose({ focusInput, isInFocus });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-component", {
      'read-only-input-disabled-movement': _ctx.readOnly && !_ctx.modelValue,
    }])
  }, [
    _withDirectives(_createElementVNode("input", {
      type: _ctx.inputType,
      placeholder: " ",
      id: 'input' + _unref(id),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((val).value = $event)),
      readonly: _ctx.readOnly,
      ref_key: "inputComponent",
      ref: inputComponent
    }, null, 8, _hoisted_1), [
      [_vModelDynamic, val.value]
    ]),
    _createElementVNode("label", {
      for: 'input' + _unref(id)
    }, [
      _createTextVNode(_toDisplayString(_ctx.placeHolder) + " ", 1),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode("- " + _toDisplayString(_ctx.errorMessage), 1)
          ], 64))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2),
    (!_ctx.readOnly)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["clear-input", { show: _ctx.modelValue }]),
          onClick: clearModelValue
        }, null, 2))
      : _createCommentVNode("", true)
  ], 2))
}
}

})