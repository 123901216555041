import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/notSupported.svg'


const _hoisted_1 = {
  key: 0,
  class: "loader-container"
}

import NavigationComponent from "@/components/NavigationComponent.vue";
import { generalStore } from "@/store/generalStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const store = generalStore();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(NavigationComponent),
    _createVNode(_component_router_view),
    (_unref(store).loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "loader" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"not-supported\"><p class=\"heading-2-bold\">Quadratura auth dashboard</p><div class=\"not-supported-box\"><img src=\"" + _imports_0 + "\"><p class=\"heading-2-bold\">The application is optimised for screen width 950 px or more.</p><p class=\"heading-2-bold\">Please resize your browser window or rotate the tablet.</p></div></div>", 1))
  ], 64))
}
}

})