import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dropdown-component-outside-text"
}
const _hoisted_2 = {
  key: 0,
  class: "search-box"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onMouseenter"]
const _hoisted_5 = {
  key: 0,
  class: "checkbox"
}
const _hoisted_6 = ["value", "id"]
const _hoisted_7 = ["for", "title"]
const _hoisted_8 = ["value", "id"]
const _hoisted_9 = ["for", "title"]
const _hoisted_10 = { key: 1 }

import { onMounted, computed, ref, nextTick, onBeforeUnmount } from 'vue';
import InputComponent from '../input-component/InputComponent.vue';

type Option =
  | {
      name: string;
      value: string;
    }
  | unknown;


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownComponent',
  props: {
    placeHolder: { default: 'Please select' },
    errorMessage: {},
    modelValue: {},
    options: {},
    displayFunction: { type: Function, default: (option: Option) => {
      return (
        (
          option as {
            name: string;
          }
        ).name ?? '--'
      );
    } },
    valueFunction: { type: Function, default: (option: Option) => {
      return (
        (
          option as {
            value: string;
          }
        ).value ?? '--'
      );
    } },
    multiSelect: { type: Boolean, default: false },
    searchBox: { type: Boolean, default: false },
    revertOption: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

const opened = ref(false);
const dropdownUpsideDown = ref(false);
const searchValue = ref('');
const inputComponent = ref<typeof InputComponent | null>();
const dropdownComponent = ref<HTMLElement | null>();
const dropdownComponentList = ref<HTMLElement | null>();

const value = computed({
  get(): unknown | null {
    if (!props.options) {
      return null;
    }
    if (props.multiSelect) {
      return props.modelValue ?? [];
    }
    for (let i = 0; i < props.options.length; i++) {
      if (props.valueFunction(props.options[i]) === props.modelValue) {
        return props.options[i];
      }
    }

    return null;
  },
  set(val: unknown | null) {
    if (val) {
      if (props.multiSelect) {
        emit('update:modelValue', val);
      } else {
        emit('update:modelValue', props.valueFunction(val));
        close();
      }
    } else if (!props.revertOption && !props.multiSelect && val === null) {
      emit('update:modelValue', val);
      close();
    }
  },
});

const filteredOptions = computed(() => {
  if (!props.options) {
    return [];
  }
  if (searchValue.value) {
    return props.options.filter((option) => {
      return props.displayFunction(option).toLowerCase().includes(searchValue.value.toLowerCase());
    });
  }
  return props.options;
});

onMounted(() => {
  checkLength();
  window.addEventListener('resize', calculateDropdownPosition);

  window.addEventListener('scroll', handleScroll, true);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', calculateDropdownPosition);

  window.removeEventListener('scroll', handleScroll, true);
});

function handleScroll(e: Event) {
  const scrolledElement = e.target as HTMLElement;
  if (scrolledElement.contains(dropdownComponent.value as Node)) {
    close();
  }
}

function checkLength() {
  if (props.options && props.options.length == 1) {
    value.value = props.options[0];
  }
}

const typed = ref('');
const timeoutTyped = ref(0);
const focusedItemIndex = ref(-1);

function handleKeyDown(e: KeyboardEvent) {
  if (e.target !== dropdownComponent.value) {
    return;
  }

  if (e.key === 'Escape' || e.key === 'Tab') {
    close();
  } else if (e.key === 'ArrowDown') {
    e.preventDefault();
    if (focusedItemIndex.value + 1 >= filteredOptions.value.length) {
      return;
    }
    focusedItemIndex.value++;

    const focusedItem = dropdownComponentList.value?.querySelectorAll('li')[focusedItemIndex.value];
    focusedItem?.scrollIntoView({ block: 'nearest' });
  } else if (e.key === 'ArrowUp') {
    e.preventDefault();
    if (focusedItemIndex.value === -1) {
      focusedItemIndex.value = filteredOptions.value.length - 1;
    } else if (focusedItemIndex.value > 0) {
      focusedItemIndex.value--;
    }

    const focusedItem = dropdownComponentList.value?.querySelectorAll('li')[focusedItemIndex.value];
    focusedItem?.scrollIntoView({ block: 'nearest' });
  } else if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar' || e.key === 'Space') {
    e.preventDefault();
    if (opened.value) {
      if (focusedItemIndex.value >= 0 && filteredOptions.value[focusedItemIndex.value]) {
        if (props.multiSelect && Array.isArray(value.value)) {
          if (value.value.includes(filteredOptions.value[focusedItemIndex.value])) {
            value.value = value.value.filter((item) => item !== filteredOptions.value[focusedItemIndex.value]);
          } else {
            value.value = [...value.value, filteredOptions.value[focusedItemIndex.value]];
          }
        } else {
          value.value = filteredOptions.value[focusedItemIndex.value];
          close();
        }
      }
    } else {
      open();
    }
  } else if (
    (e.keyCode >= 48 && e.keyCode <= 57) ||
    (e.keyCode >= 65 && e.keyCode <= 90) ||
    (e.keyCode >= 96 && e.keyCode <= 105)
  ) {
    e.preventDefault();
    typed.value += e.key;
    const filteredOptions = props.options?.filter((option) => {
      return props.displayFunction(option).toLowerCase().startsWith(typed.value.toLowerCase());
    });
    if (timeoutTyped.value) {
      clearTimeout(timeoutTyped.value);
    }

    timeoutTyped.value = window.setTimeout(() => {
      typed.value = '';
    }, 1000);
    if (filteredOptions && filteredOptions?.length > 0) {
      focusedItemIndex.value = props.options?.indexOf(filteredOptions[0]) ?? -1;
      const focusedItem = dropdownComponentList.value?.querySelectorAll('li')[focusedItemIndex.value];
      focusedItem?.scrollIntoView({ block: 'nearest' });
      setTimeout(() => {
        focusedItemIndex.value = props.options?.indexOf(filteredOptions[0]) ?? -1;
      }, 10);
    }
  }
}

function displaySelected() {
  if (value.value) {
    return props.displayFunction(value.value);
  }
  return props.placeHolder;
}

function open() {
  if (opened.value) {
    return;
  }
  opened.value = true;

  nextTick(() => {
    dropdownComponent.value?.focus();
    calculateDropdownPosition();
    nextTick(() => {
      if (props.options) {
        if (props.multiSelect) {
          for (let i = 0; i < props.options.length; i++) {
            if (Array.isArray(props.modelValue) && props.modelValue.includes(props.options[i])) {
              focusedItemIndex.value = i;
              const focusedItem = dropdownComponentList.value?.querySelectorAll('li')[focusedItemIndex.value];
              focusedItem?.scrollIntoView({ block: 'start' });
              break;
            }
          }
        } else {
          for (let i = 0; i < props.options?.length; i++) {
            if (props.valueFunction(props.options[i]) === props.modelValue) {
              console.log(props.valueFunction(props.options[i]), props.modelValue);
              focusedItemIndex.value = i;
              const focusedItem = dropdownComponentList.value?.querySelectorAll('li')[focusedItemIndex.value];
              focusedItem?.scrollIntoView({ block: 'start' });
            }
          }
        }
      }
      if (props.searchBox) {
        inputComponent.value?.focusInput();
      }
    });
  });
}

function close() {
  if (!opened.value) {
    return;
  }
  opened.value = false;
  dropdownUpsideDown.value = false;
  searchValue.value = '';
  focusedItemIndex.value = -1;
}

function calculateDropdownPosition() {
  nextTick(() => {
    const dropdownComponentListPosition = dropdownComponentList.value?.getBoundingClientRect();
    const dropdownPosition = dropdownComponent.value?.getBoundingClientRect();
    const MIN_HEIGHT = 100;
    const MIN_GAP = 50;

    if (!dropdownComponentListPosition || !dropdownPosition) {
      return;
    }

    let parentElement = dropdownComponentList.value?.parentElement;
    let height = 0;
    while (parentElement) {
      if (parentElement === document.body) {
        height = window.innerHeight;
        break;
      }
      if (parentElement.scrollHeight > parentElement.clientHeight) {
        height = parentElement.getBoundingClientRect().bottom;
        break;
      }

      parentElement = parentElement.parentElement;
    }

    const spaceToBottom = height - dropdownPosition.bottom;

    const spaceRequired = dropdownComponentListPosition.height;
    let top = dropdownPosition.bottom + 'px';
    const left = dropdownPosition.left + 'px';
    let bottom = 'auto';
    let maxHeight = Math.max(spaceToBottom, MIN_HEIGHT) - MIN_GAP;

    if (Math.min(spaceRequired, 250) <= spaceToBottom) {
      dropdownUpsideDown.value = false;
    } else {
      dropdownUpsideDown.value = true;
      top = 'auto';
      const spaceToBottom = window.innerHeight - dropdownPosition.bottom;
      bottom = spaceToBottom + dropdownPosition.height + 'px';
      maxHeight = Math.max(dropdownPosition.top, MIN_HEIGHT) - MIN_GAP;
    }

    dropdownComponentList.value?.setAttribute(
      'style',
      `top: ${top};
       left: ${left};
       bottom: ${bottom};
       width: ${dropdownPosition.width}px;
       max-height: ${maxHeight}px;
      `
    );
  });
}

function getMultiSelectNames() {
  const selectedValue = value.value as unknown[];
  if (props.multiSelect && selectedValue && selectedValue?.length > 0) {
    let names = '';
    for (let i = 0; i < selectedValue?.length; i++) {
      if (!props.options) return null;
      for (let j = 0; j < props.options.length; j++) {
        if (props.options[j] === selectedValue[i]) {
          names += props.displayFunction(props.options[j]) + ', ';
        }
      }
      names = names.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
    names = names.slice(0, -2);
    if (selectedValue?.length > 3) {
      names = selectedValue?.length + ' ' + props.placeHolder + 's' + ' selected ';
    }
    return names;
  }
  return null;
}

function closeViaBlur(e: FocusEvent) {
  if (
    !dropdownComponent.value?.contains(e.relatedTarget as Node) &&
    !dropdownComponentList.value?.contains(e.relatedTarget as Node)
  ) {
    close();
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "dropdown-component",
    ref_key: "dropdownComponent",
    ref: dropdownComponent,
    tabindex: "0",
    onFocusin: open,
    onKeydown: handleKeyDown,
    onFocusout: _cache[5] || (_cache[5] = ($event: any) => (closeViaBlur($event)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown-component-outside", { 'up-side-down': dropdownUpsideDown.value && opened.value, 'dropdown-list-opened': opened.value }]),
      onMousedown: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (opened.value ? close() : open()), ["prevent"]))
    }, [
      (_ctx.multiSelect ? getMultiSelectNames() : value.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.errorMessage ? _ctx.placeHolder + ' - ' + _ctx.errorMessage : _ctx.placeHolder), 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", {
        class: _normalizeClass(["dropdown-component-outside-title", { 'normal-color': _ctx.multiSelect ? getMultiSelectNames() : value.value }])
      }, _toDisplayString(_ctx.multiSelect
            ? getMultiSelectNames()
              ? getMultiSelectNames()
              : _ctx.errorMessage
              ? _ctx.placeHolder + ' - ' + _ctx.errorMessage
              : _ctx.placeHolder
            : value.value && displaySelected()
            ? displaySelected()
            : _ctx.errorMessage
            ? _ctx.placeHolder + ' - ' + _ctx.errorMessage
            : _ctx.placeHolder), 3),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "dropdown-arrow-box" }, null, -1))
    ], 34),
    (opened.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["dropdown-component-opened", { 'up-side-down': dropdownUpsideDown.value }]),
          ref_key: "dropdownComponentList",
          ref: dropdownComponentList
        }, [
          (_ctx.searchBox)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(InputComponent, {
                  ref_key: "inputComponent",
                  ref: inputComponent,
                  modelValue: searchValue.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchValue).value = $event)),
                  placeHolder: "Search"
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("ul", null, [
            (!_ctx.multiSelect && !_ctx.revertOption)
              ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                  _withDirectives(_createElementVNode("input", {
                    type: "radio",
                    value: null,
                    id: "null_option",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((value).value = $event))
                  }, null, 512), [
                    [_vModelRadio, value.value]
                  ]),
                  _cache[7] || (_cache[7] = _createElementVNode("label", { for: "null_option" }, null, -1))
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredOptions.value, (option, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                onMouseenter: ($event: any) => (focusedItemIndex.value = index),
                class: _normalizeClass({
            highlighted: focusedItemIndex.value === index,
          })
              }, [
                (_ctx.multiSelect)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        value: option,
                        id: _ctx.valueFunction(option) + '_' + index,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((value).value = $event))
                      }, null, 8, _hoisted_6), [
                        [_vModelCheckbox, value.value]
                      ]),
                      _createElementVNode("label", {
                        for: _ctx.valueFunction(option) + '_' + index,
                        title: _ctx.displayFunction(option),
                        class: "body-text radio-label"
                      }, _toDisplayString(_ctx.displayFunction(option)), 9, _hoisted_7)
                    ]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _withDirectives(_createElementVNode("input", {
                        type: "radio",
                        value: option,
                        id: _ctx.valueFunction(option) + '_' + index,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((value).value = $event))
                      }, null, 8, _hoisted_8), [
                        [_vModelRadio, value.value]
                      ]),
                      _createElementVNode("label", {
                        for: _ctx.valueFunction(option) + '_' + index,
                        title: _ctx.displayFunction(option)
                      }, _toDisplayString(_ctx.displayFunction(option)), 9, _hoisted_9)
                    ], 64))
              ], 42, _hoisted_4))
            }), 128)),
            (filteredOptions.value.length == 0)
              ? (_openBlock(), _createElementBlock("li", _hoisted_10, _cache[8] || (_cache[8] = [
                  _createElementVNode("label", null, "No results found", -1)
                ])))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 544))
}
}

})