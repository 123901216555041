import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navigation-component" }
const _hoisted_2 = { class: "navigation-links" }

import { RouteRecordName } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'NavigationComponent',
  setup(__props) {

function includesName(outter: RouteRecordName | null | undefined, inner: string) {
  if (!outter) return false;
  const outterString = outter.toString();
  return outterString.includes(inner);
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: { name: 'UsersView' },
      class: "navigation-title"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Authentication dashboard")
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: _normalizeClass(["navigation-item list-text-bold", {
          active: includesName(_ctx.$route.name, 'User'),
        }]),
        to: { name: 'UsersView' }
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Users")
        ])),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_router_link, {
        class: _normalizeClass(["navigation-item list-text-bold", {
          active: includesName(_ctx.$route.name, 'Resource'),
        }]),
        to: { name: 'ResourcesView' }
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Resources")
        ])),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_router_link, {
        class: _normalizeClass(["navigation-item list-text-bold", {
          active: includesName(_ctx.$route.name, 'Clients'),
        }]),
        to: { name: 'ClientsView' }
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Clients")
        ])),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_router_link, {
        class: _normalizeClass(["navigation-item list-text-bold", {
          active: includesName(_ctx.$route.name, 'Systems'),
        }]),
        to: { name: 'SystemsView' }
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("Systems")
        ])),
        _: 1
      }, 8, ["class"])
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "navigation-end" }, [
      _createElementVNode("a", {
        href: "/logout",
        class: "navigation-item list-text-bold"
      }, " Logout ")
    ], -1))
  ]))
}
}

})